@use "../abstracts/sizes" as *;

.navbar {
  background-color: #252526;
}

.navbar-content {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  max-width: var(--container-width);
  width: 100%;
  flex-wrap: wrap;
}

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-collapse {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-align-items: center;
  align-items: center;
}

.navbar.white {
  background: #252526 !important;
  box-shadow: 0 2px 35px 0 rgb(0 0 0 / 10%);
  top: 0;
}

.navbar-light .navbar-nav .nav-link:focus:not(.active),
.navbar-light .navbar-nav .nav-link:hover:not(.active) {
  color: #969696 !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.navbar-custom {
  z-index: 999;
  padding: 0;
  background-color: #252526;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.navbar-custom .logo {
  float: left;
  color: #343a40;
  font-weight: 700;
  padding: 0px 0;
  letter-spacing: 1px;
}

.collapse:not(.show) {
  display: none;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.app-options {
  color: #868686;
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-left: 8px;
}

.app-options ul {
  list-style: none;
  display: flex;
  column-gap: 8px;
  font-family: "Source Sans Pro";
  font-size: 14px;
  flex-direction: row;
  height: 22px;
  line-height: 22px;
}

.app-options li {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: default;

  &:hover {
    background-color: #3f4040;
    color: #999999;
  }
}

@media (min-width: 991px) {
  .navbar {
    height: $navbar-height;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar .navbar-nav {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .navbar .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .navbar .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-toggler {
    height: 70px;
  }
}

@media (max-width: 991px) {
  .navbar-content {
    padding-left: 0;
    padding-right: 0;
  }
}
