#contact {
  padding-bottom: 220px;
}

.contact-div {
  display: grid;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-items: center;
  align-items: center;
}

.rrss-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-items: stretch;
  align-items: center;
}

.social-item {
  padding: 1.2rem;

  .card-title {
    font-size: 1rem;
  }
  .card-text {
    font-size: 0.8rem;
  }
  .card-header {
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 0.6rem;
  }
  .card-icon {
    width: 1.3rem;
  }
  &:hover {
    transform: scale(1.03);
  }
}

.social-item:hover {
  .twitter .card-title {
    color: #1da1f2;
  }
  .instagram .card-title {
    color: #e4405f;
  }
  .linkedin .card-title {
    color: #0a66c2;
  }
  .github .card-title {
    color: #3d3d3d;
  }
  .spotify .card-title {
    color: #1ed760;
  }
  .steam .card-title {
    color: #3d3d3d;
  }
}

@media (max-width: 700px) {
  .contact-container {
    grid-template-columns: 1fr;
  }
  .rrss-grid {
    grid-template-columns: 1fr;
    grid-row-start: 2;
  }
  .email-area {
    margin-bottom: 24px;
  }
}
