.about {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
  align-items: flex-start;
}

.about-me {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.about-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 1rem 0;
}

.card {
  background-color: #1e1e1e;
  border: 1px solid #454545;
  border-radius: 0.5rem;
  color: #d4d4d4;
  cursor: default;
  padding: 1.5rem;
  max-width: 26rem;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);

    .card-title {
      color: #2f80ed;
    }
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-title {
  font-size: 1.25rem;
  line-height: 1;
  transition: color 0.3s ease-in-out;
}

.card-icon {
  width: 1.5rem;
}

@media (max-width: 768px) {
  .about {
    grid-template-columns: 1fr;
  }

  .about-text {
    margin-bottom: 16px;
  }

  .about-cards {
    grid-row-start: 2;
    justify-content: center;
  }
}
