.desktop-icon {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 10px 40px 10px;
  text-align: center;
  height: 70px;
  width: 120px;

  .icon-wrapper {
    width: 48px;
    height: 48px;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
  }

  img {
    height: 48px;
    width: 48px;
    margin-bottom: 5px;
    image-rendering: pixelated;
  }

  .title {
    margin-top: 5px;
    position: relative;
    text-align: center;
    width: 100%;
  }

  &.selected {
    .selection-effect {
      display: block;
    }
    .title {
      background: #000080;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        border: 1px dotted #ffffff;
        mix-blend-mode: exclusion;
      }
    }
  }

  .selection-effect {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    mask-image: var(--icon-image);
    -webkit-mask-image: var(--icon-image);
    background: #000080;
    opacity: 0.5;
  }
}
