.window {
  border: 2px solid #7c7c7c;
  border-top-color: #fff;
  border-bottom-color: #131313;
  border-left-color: #fff;
  background: #c0c0c0;
  font-family: "MS Sans Serif";
  width: 90%;
  max-width: 500px;
  position: fixed;
  top: 15%;
  left: 20%;
  resize: both;
  display: none;
  z-index: 0;

  &.open,
  &.unfocused {
    display: block;
  }

  &.active {
    z-index: 1;
  }

  &.unfocused {
    .window-toolbar {
      background: linear-gradient(to right, #808080 0%, #b5b5b5 100%);
      color: #c0c0c0;
    }
  }

  .window-toolbar {
    background: #000080;
    color: #fff;
    line-height: 14px;
    padding: 4px;
    font-weight: bold;

    .window-buttons {
      position: absolute;
      right: 2px;
      top: 2px;

      .window-button {
        background: no-repeat center #ccc;
        border: 2px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        height: 17px;
        width: 17px;
        float: right;
        margin-left: 2px;

        &:active {
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          border-right: 2px solid #efefef;
          border-bottom: 2px solid #efefef;
        }

        &.window-close {
          background-image: url("/./images/icons/close-icon.png");
        }
        &.window-help {
          background-image: url("/./images/icons/question-icon.png");
        }
        &.window-minimize {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='6' height='2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M0 0h6v2H0z'/%3E%3C/svg%3E");
        }
      }
    }
  }

  .options {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 1.25rem;
    position: relative;

    &.address {
      align-items: center;
      padding: 0.75rem 0.5rem 0.5rem 1.25rem;

      input {
        border: none;
        height: 22px;
        width: 100%;
        padding: 0.25rem;
        box-sizing: border-box;
      }
    }

    > div {
      padding: 0 1rem 0 0;
      opacity: 0.5;
    }

    &:before {
      content: "";
      width: 2px;
      height: calc(100% - 1rem);
      position: absolute;
      background: #f2f2f2;
      box-shadow: 1px 1px 0 #818181;
      left: 0.5rem;
      top: 0.5rem;
    }
  }

  .window-wrapper {
    height: 220px;
    padding: 10px;

    &.white {
      background: white;
      padding: 1rem;
      box-sizing: border-box;
      display: flex;
      margin-top: 2px;
    }

    &.gray {
      background: #c0c0c0;
      padding: 1rem;
      box-sizing: border-box;
      display: flex;
      margin-top: 2px;
    }

    .window-content {
      padding: 15px;
      border-top: none;
    }

    .window-actions {
      position: absolute;
      bottom: 20px;
      right: 20px;
      margin-top: 10px;
      overflow: hidden;
    }

    .window-action-button {
      border: 1px solid #131313;
      border-top-color: #fff;
      border-left-color: #fff;
      background: #c0c0c0;
      line-height: 24px;
      height: 27px;
      width: 75px;
      text-align: center;

      float: right;
      margin-left: 5px;

      &:active {
        border-top: 2px solid #000;
        border-left: 2px solid #000;
        border-right: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
      }
    }
  }
}

.update-wrapper {
  .title {
    font-size: 15px;
    margin: 1rem 0 0 0;
  }
}
.update-img {
  height: 65px;
  width: 20rem;
}

#install-bar {
  border: 2px inset;
  height: 1.5rem;
  margin: 1rem 0;
  display: flex;
}

#install-bar > div {
  background: #000b7c;
  height: calc(100% - 4px);
  width: 1.28rem;
  margin: 0.125rem;
}

@for $i from 1 through 26 {
  #install-bar > div:nth-of-type(#{$i}) {
    animation: show .3s forwards 1 ($i * 2.4s);
  }
}

#install-bar > div {
  opacity: 0;
}

@keyframes show {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: all;
  }
}
