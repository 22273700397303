footer {
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.footer-icons {
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
}

.footer-icons div {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  padding: 0 8px;
  height: 100%;
}

.footer-icons div:first-child svg {
  height: 14px;
}

.footer-icons:first-child div:first-child {
  background: #16825d;
}

.footer-icons:first-child div:first-child:hover {
  background: #308166;
}

.footer-icons div:hover {
  background: rgba(255, 255, 255, 0.1);
}

.footer-icons svg {
  display: inline-block;
  height: 18px;
  width: 18px;
  stroke: white;
}

.footer-area {
  background: #007acc;
  height: 24px;
}

.footer-area .footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.branch {
  color: #fff;
  font-size: 12px;
  margin-right: 16px;
}

.branch img {
  margin-top: -5px;
  width: 18px;
}

@media (max-width: 576px) {
  .footer-area .footer-content .text-right {
    text-align: center !important;
    padding-top: 16px;
  }
}

@media (max-width: 848px) {
  .exceed {
    display: none !important;
  }
}

.footer-content ul li {
  display: inline-block;
  margin-top: 2px;
}

.footer-content ul li a {
  color: #fff;
  margin-right: 16px;
  display: inline-block;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.footer-content ul li a:hover {
  cursor: pointer;
}

.footer-content span {
  font: 400 10px/18px;
  color: #fff;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 25px;
}
