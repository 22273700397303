.section {
  padding: 120px 0;
  position: relative;
  z-index: 1;
}

.section:last-of-type {
  margin-top: 50px;
  padding: 120px 0 80px 0;
}

@media only screen and (max-width: 767px) {
  footer .container {
    width: unset !important;
    max-width: 100% !important;
  }

  .section,
  .section.alt {
    padding: 60px 0 !important;
  }

  .section-title {
    margin-bottom: 20px !important;
  }

  #about .section-title {
    padding-top: 40px !important;
  }

  #contact .section-title {
    margin-bottom: 40px !important;
  }

  .section-title h2 {
    font-size: 36px !important;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    width: 360px !important;
  }

  #resume h1 {
    font-size: 36px;
    margin-top: 10px;
  }
}

.section.alt {
  background-color: #1e1e1e;
}

.section-title {
  margin-bottom: 70px;
}

.section-title p {
  color: #d4d4d4;
  font-size: 16px;
  font-weight: 400;
}

.section-title h2 {
  font-size: 42px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 16px;
}

.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}
