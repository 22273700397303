@use "../abstracts/sizes" as *;

.tech-box {
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 10px 60px 0px rgb(46 61 98 / 10%);
  border-radius: 5px;
  position: relative;
  margin-bottom: 16px;
  margin-right: 15px;
  z-index: 10;
  background: #fff;
  color: #252c41;
  padding: 25px 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transition: all 0.3s ease;
    height: $tech-logo-size;
    width: $tech-logo-size;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.tech-name {
  color: #fff;
}

.tech-box:hover img {
  margin-top: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 24px;
}

.techs .group {
  margin-bottom: 40px;
}

.techs h3 {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 16px;
}

.tech-card {
  border-radius: 0.5rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  gap: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.3s ease;

  img {
    height: 32px;
    width: 32px;
  }

  h3 {
    color: #444;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    white-space: nowrap;
  }

  &:hover {
    transform: scale(1.04);
  }
}
