.back-top {
  position: fixed;
  border-radius: 5px;
  bottom: 20px;
  bottom: var(--cd-back-to-top-bottom);
  right: 20px;
  right: var(--cd-back-to-top-margin);
  display: inline-block;
  height: 40px;
  height: var(--cd-back-to-top-size);
  width: 40px;
  width: var(--cd-back-to-top-size);
  box-shadow: 0 0 10px rgb(0 0 0 / 5%);
  background: url(../../top-arrow.svg), #2f80ed;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9;
  transition: all 0.3s, visibility 0.3s, background-color 0.3s;

  &.hidden {
    width: 0;
    height: 0;
    opacity: 0;
  }

  &:hover {
    background-position-y: 15px;
  }
}

@media (max-width: 768px) {
  .back-top {
    bottom: 10px;
    right: 10px;
    background-size: 12px;
    &:hover {
      background-position-y: 12px;
    }
  }
}
