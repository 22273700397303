.resume {
  background: #1e1e1e;
  color: #d4d4d4;
  font-weight: 500;
  line-height: normal;
  padding: 200px 0;
  font-family: "Poppins", sans-serif;
}

.resume-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 56px;
}

.greetings {
  font-size: 1.5rem;
  font-weight: 500;
}

.resume h1 {
  font-size: calc(22px + (70 - 22) * ((100vw - 300px) / (1920 - 300)));
  margin-bottom: 5px;
  line-height: normal;
}

.resume .font-large {
  color: #d4d4d4;
  font-size: 120%;
}

.header-sub-text p {
  font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 300;
}

.job {
  font-size: 1.25rem;
}

.header-sub-text {
  margin-bottom: 30px;
}

.Typewriter {
  display: inline;
}

@media (max-width: 768px) {
  .resume-content {
    grid-template-columns: 1fr;
  }
  .greetings {
    font-size: 1.25rem;
  }
  .resume {
    padding: 60px 0;
    h1 {
      font-size: 3rem;
    }
  }
  .resume img {
    margin: 0 auto;
    width: 75%;
  }
}
