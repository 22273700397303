@font-face {
  font-family: "JetBrains Mono Regular"; /*Can be any text*/
  src: local("JetBrains Mono Regular"),
    url("../../fonts/JetBrainsMono-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans"; /*Can be any text*/
  src: local("Open Sans"),
    url("../../fonts/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins"; /*Can be any text*/
  font-weight: 400;
  src: local("Poppins"),
    url("../../fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins"; /*Can be any text*/
  font-weight: 500;
  src: local("Poppins"),
    url("../../fonts/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins"; /*Can be any text*/
  font-weight: 600;
  src: local("Poppins"),
    url("../../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro"; /*Can be any text*/
  font-weight: 400;
  src: local("Source Sans Pro"),
    url("../../fonts/SourceSans3-VariableFont_wght.ttf") format("truetype");
  font-display: swap;
}
