.experience-list {
    margin-bottom: -30px;
}

.media-body {
    flex: 1 1;
}

.timeline {
    position: relative;
    margin: 0 0 0 30px;
    color: white;
}

.timeline .timeline-container {
    position: relative;
    margin-bottom: 2.5rem;
}
.timeline .timeline-container .timeline-icon {
    position: absolute;
    left: -88px;
    top: 18px;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    text-align: center;
    font-size: 2rem;
    background: #4f537b;
}
.timeline .timeline-container .timeline-icon i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.timeline .timeline-container .timeline-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.timeline .timeline-container .timeline-body {
    background: #fff;
    border-radius: 10px;
    padding: 30px 30px 30px 30px;
    box-shadow: 1px 3px 9px rgba(0, 0, 0, .1);
}
.timeline .timeline-container .timeline-body:before {
    content: '';
    background: inherit;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    top: 35px;
    left: -10px;
    transform: rotate(45deg);
    border-radius: 0 0 0 2px;
}
.timeline .timeline-container .timeline-body .timeline-title {
    color: #051441;
    font-weight: 600;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    transition: color .2s linear;
}
.timeline .timeline-container .timeline-body .timeline-subtitle {
    margin-bottom: 0;
    font-size: 16px;
    color: #545454;
    margin-top: 8px;
    margin-bottom: 14px;
    line-height: 1.6;
}
.timeline .timeline-container .timeline-body span {
    color: #545454;
    opacity: .6;
    font-size: 15px;
    line-height: 1;
}
.timeline .timeline-container .timeline-body .timeline-lapse {
    font-size: 14px;
    font-style: italic;
}