.appName {
  background-color: #323233;
  color: #cccccc;
  font-size: 11px;
  height: 35px;
  line-height: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.appName img {
  height: 26px;
}

.appTitle {
  flex: 1;
  display: flex;
  justify-content: center;
}

.appControls {
  display: flex;
}

.appMinimize,
.appMaximize,
.appClose {
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  background-color: #323233;
  color: #898989;
  font-size: 18px;
  height: 35px;
  line-height: 32px;

  img {
    max-width: unset;
  }
}

.appMinimize:hover,
.appMaximize:hover {
  background-color: #4d4d4e;
}

.appClose:hover {
  background-color: #d71526;
}

.appClose:hover img {
  filter: brightness(100%);
}

.appMinimize img,
.appMaximize img {
  filter: brightness(65%);
  width: 18px;
  height: 18px;
}

.appClose img {
  filter: brightness(80%);
  width: 18px;
}

.logo,
.logo img {
  width: 104px;
  transition: transform ease 0.3s;
}

.logo:hover {
  transform: scale(1.05);
}

.toolbar .nav-link {
  font: 400 15px/30px "Roboto", sans-serif;
  color: #fff;
  position: relative;
  padding: 6px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.nav-link img {
  display: inline-block;
  margin-top: -2px;
  margin-right: 10px;
}

.navbar-custom .nav-social {
  margin-left: 25px;
}

.navbar .navbar-nav .nav-item {
  background-color: #2d2d2d;
  margin-right: 2px;
}

.navbar .navbar-nav .nav-item a {
  color: #969696;
  padding: 10px 32px 10px 10px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    width: 14px; /* Ajusta el tamaño del SVG */
    height: 14px; /* Ajusta el tamaño del SVG */
    background-image: url("../../close.svg");
    background-size: cover;
    color: #969596;
    opacity: 0;
  }
}

.navbar .navbar-nav .nav-item a.active {
  background-color: #1e1e1e;
  color: #e2c08d;
}

.navbar .navbar-nav .nav-item:hover {
  background-color: #323233;
  ::after {
    opacity: 1;
  }
}

.navbar .navbar-nav .nav-item:hover a {
  color: #e2c08d;
}

.navbar-custom .navigation .nav-item .nav-link {
  font: 600 14px/30px "Montserrat", sans-serif;
  color: #040c2c;
  text-transform: uppercase;
  position: relative;
  padding: 0px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.navbar-custom .navigation .nav-item .nav-link:before {
  content: "";
  background: #fff;
  opacity: 0.71;
  position: absolute;
  left: auto;
  right: 0px;
  bottom: 0px;
  width: 0%;
  height: 1.3px;
  z-index: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
}

.navbar-toggler {
  border: 0;
  padding: 0;
  border-radius: 0;
  height: 80px;
  cursor: pointer;
}

.navbar-toggler span {
  display: block;
  width: 30px;
  height: 2px;
  background-image: -ms-linear-gradient(
    0deg,
    #ed3d82 0,
    #f66264 75%,
    #fe8645 100%
  );
  margin: auto auto 5px;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
}

.navbar-toggler[aria-expanded="true"] span:first-child {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: 6px;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(42deg);
  transform: rotate(42deg);
  bottom: 8px;
  position: relative;
}

@media (max-width: 991px) {
  .appName {
    display: none;
  }
  .appControls {
    display: none;
  }

  .navbar {
    padding: 0 15px;
  }

  .navbar-nav {
    background: #1e1e1e;
    padding: 16px;
  }

  .navbar .navbar-nav .nav-item {
    margin-left: 0;
  }

  .navbar-toggler span,
  .navbar .navbar-nav .nav-item .nav-link:before,
  .navbar-toggler span {
    background: #444;
  }
}

@media (max-width: 1140px) {
  .navbar .navbar-nav .nav-item a {
    font-size: 12px;
    padding: 10px 10px 10px 10px;
  }
}
