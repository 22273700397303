.container {
  width: var(--container-width);
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 24px;
  }
}
