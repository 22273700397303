body {
  background-color: #252526 !important;
  font-family: "Poppins", sans-serif !important;
  position: relative;
  font-size: 14px !important;
}

h1 {
  font-size: calc(22px + (50 - 22) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 400;
  letter-spacing: 2px;
  margin-top: -7px;
  margin-bottom: -20px;
  line-height: 1.4;
}

p {
  font-size: calc(13px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
  color: var(--secondary);
  font-weight: 400;
  margin-top: -7px;
  margin-bottom: -4px;
  line-height: 1.8;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-top {
  top: 0;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#cursor {
  background: #d4d4d4;
  display: inline-block;
  margin-left: 3px;
  margin-bottom: -1px;
  animation: blink 1s infinite;
  width: 5px;
  height: 16px;
}

@keyframes blink {
  0% {
    background: #222;
  }
  50% {
    background: #d4d4d4;
  }
  100% {
    background: #222;
  }
}
