.file-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    width: 75px;
    height: 75px;

    .icon-wrapper {
        width: 32px;
        height: 32px;
        position: relative;
        display: inline-block;
        vertical-align: bottom;
    }

    img {
        height: 32px;
        width: 32px;
    }

    .title {
        font-size: 9pt;
        line-height: 13px;
        margin-top: 5px;
        padding: 1px;
        position: relative;
        text-align: center;
        word-break: break-all;
    }

    &.selected {
        .selection-effect {
            display: block;
        }
        .title {
            background: #000080;
            color: #FFFFFF;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                border: 1px dotted #ffffff;
                mix-blend-mode: exclusion;
            }
        }
    }

    .selection-effect {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 32px;
        height: 32px;
        mask-image: var(--icon-image);
        mask-size: 32px;
        -webkit-mask-image: var(--icon-image);
        background: #000080;
        opacity: 0.5;
    }

}